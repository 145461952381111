/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { useI18n } from "vue-i18n";
import "vuetify/styles";
import { createVuetify as createVuetifyFromLib } from "vuetify";
import { VTimePicker } from "vuetify/labs/VTimePicker";

/**
 * https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
 * @param {import("vue-i18n").I18n} i18n
 * @returns {import("vue").Plugin}
 */
export function createVuetify(i18n) {
  return createVuetifyFromLib({
    components: {
      VTimePicker,
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#07327B",
            "link-primary": "#07327B",
            "text-primary": "#07327B",
            "on-primary": "#FFFFFF",
            secondary: "#00bea5",
            "on-secondary": "#FFFFFF",
            "link-secondary": "#00bea5",
            "text-secondary": "#00bea5",
            accent: "#12239F",
            "link-accent": "#12239F",
            "text-accent": "#12239F",
            "on-accent": "#FFFFFF",
            error: "#d93125",
            warning: "#e36c00",
            info: "#008ac8",
            success: "#019002",
            background: "#F3F3F3",
            header: "#FBFBFD",
            footer: "#A6ADB4",
            "on-footer": "#FFFFFF",
            third: "#ebeded",
          },
        },
      },
    },
    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    defaults: {
      VAppBar: {
        density: "comfortable",
      },
      VTextField: {
        density: "comfortable",
        variant: "outlined",
      },
      VSelect: {
        density: "comfortable",
        variant: "outlined",
        persistentHint: true,
      },
      VSwitch: {
        color: "secondary",
        density: "comfortable",
        variant: "outlined",
        hideDetails: "auto",
        persistentHint: true,
      },
      VRadio: {
        density: "comfortable",
        variant: "outlined",
        hideDetails: "auto",
        persistentHint: true,
      },
      VCheckbox: {
        density: "comfortable",
        variant: "outlined",
        hideDetails: "auto",
        persistentHint: true,
      },
      VTextarea: {
        density: "comfortable",
        variant: "outlined",
      },
      VFileInput: {
        density: "comfortable",
        variant: "outlined",
      },
      VAutocomplete: {
        density: "comfortable",
        variant: "outlined",
        persistentHint: true,
      },
      VCombobox: {
        density: "comfortable",
        variant: "outlined",
        persistentHint: true,
      },
      VChip: {
        density: "comfortable",
        variant: "flat",
      },
      VBtn: {
        color: "secondary",
        variant: "elevated",
        rounded: "xl",
        VIcon: {
          size: "large",
        },
      },
      VCardActions: {
        VBtn: {
          color: "secondary",
          variant: "elevated",
          rounded: "xl",
          VIcon: {
            size: "large",
          },
        },
      },
      VCard: {
        rounded: "lg",
      },
      VList: {
        density: "comfortable",
        lines: "one",
      },
      VListItem: {
        density: "comfortable",
      },
      VAlert: {
        density: "comfortable",
      },
      VDialog: {
        width: "98%",
        maxWidth: "600",
      },
      VTooltip: {
        color: "white",
      },
      VToolbar: {
        density: "comfortable",
      },
      VDataTable: {
        itemsPerPageOptions: [10, 25, 50, 100],
      },
      VDataTableServer: {
        itemsPerPageOptions: [10, 25, 50, 100],
      },
    },
  });
}
